(function($) {
	/**
	 * -------------------------------------------------------------------------------------------------------------------
	 * Equal Heights Plugin
	 * -------------------------------------------------------------------------------------------------------------------
	 *
	 * @author David fravigar <david@tjs.co.uk>
	 * @version 1.0.0
	 * -------------------------------------------------------------------------------------------------------------------
	 */
	jQuery.fn.equalHeights = function(options) {
		var settings = jQuery.extend({
			row: 				'.js-equal-heights-row',
			item:				'.js-equal-heights-item',
			elements: 	['.image', '.title', '.description', '.link']
		}, options);

		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 * Vars
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		var loop = 0;
		var heights = {};

		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 * Create Heights Object
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		function createHeightsObject() {
			$.each(settings.elements, function(index, value) {
				heights[value] = 0;
			});
		}

		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 * Reset Heights object
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		function resetHeightsObject() {
			$.each(heights, function(index, value) {
				heights[index] = 0;
			});
		}

		//create heights object
		createHeightsObject();

		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 * The actual calculations part
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		this.find(settings.row).each(function() {
			var $row = $(this);
			if(loop > 0) {
				resetHeightsObject();
			}
			//look for each item
			$row.find(settings.item).each(function() {
				var $item = $(this);
				//look for each element to set heights on
				$.each(heights, function(index, value) {
					var height = $($item).find(index).height();
					if(height > value) {
						heights[index] = height;
					}
				});
			});

			//set the row elements heights
			$.each(heights, function(index, value) {
				$row.find(index).css('min-height', value+'px');
			});
		});
		loop++;
	};

	var desktopWidth = 768;
	var viewport;

	/**
	 * -----------------------------------------------------------------------------------------------------------------
	 *
	 * -----------------------------------------------------------------------------------------------------------------
	 */
	function getViewportDimensions() {
		var w=window,
				d=document,
				e=d.documentElement,
				g=d.getElementsByTagName('body')[0],
				x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
		return { width:x,height:y };
	}

	/**
	 * -----------------------------------------------------------------------------------------------------------------
	 *
	 * -----------------------------------------------------------------------------------------------------------------
	 */
	function getVeiwPort() {
		viewport = getViewportDimensions();
		if(viewport.width >= desktopWidth) {
			return 'desktop';
		} else {
			return 'mobile';
		}
	}

	$(document).ready(function() {
		console.log('equalHeights');

		function setEqualHeights() {
			var state = getVeiwPort();

			if(state === 'mobile') {
				$('body').find('.js-equal-heights').each(function(){
					$(this).find('.page-section-content .mk-image').css('min-height', '0');
					$(this).find('.page-section-content .mk-fancy-title').css('min-height', '0');
					$(this).find('.page-section-content .mk-text-block').css('min-height', '0');
					$(this).find('.page-section-content .mk-button-container').css('min-height', '0');
				});
			} else {
				$('body').find('.js-equal-heights').each(function(){
					$(this).equalHeights({
						row: 				'.page-section-content',
						item:				'.vc_col-sm-4',
						elements: 	['.mk-image', '.mk-fancy-title', '.mk-text-block', '.mk-button-container']
					});
				});

				$('.js-equal-heights.blog').each(function() {
					$(this).equalHeights({
						row: 				'.mk-blog-container',
						item: 			'.mk-blog-grid-item',
						elements: 	['.news-category', '.the-title']
					});
				});

			$('.js-equal-heights.offices').each(function() {
					$(this).equalHeights({
						row: 				'.page-section-content',
						item: 			'.vc_col-sm-4',
						elements: 	['.widget_contact_info ', '.mk-button-container']
					});
				});
			}
		}

		setEqualHeights();
		$(window).resize(setEqualHeights);
	});
})(jQuery);






