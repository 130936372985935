(function($) {
	$(document).ready(function() {
		var desktopWith = 1140;
		var $topbar = $('.mk-secondary-menu');
		var hasSetupMobile = false;




		function getViewportDimensions() {
			var w=window,
					d=document,
					e=d.documentElement,
					g=d.getElementsByTagName('body')[0],
					x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
			return { width:x,height:y };
		}

		function responsiveInit() {
			var viewport = getViewportDimensions();
			if(viewport.width < desktopWith) {
				if(!hasSetupMobile) {
					$('body').find('.mk-responsive-topbar-menu__button').on('click', function() {
						$('.mk-responsive-topbar-menu--wrapper').slideToggle();
						$(this).toggleClass('is-open');
					});
					hasSetupMobile = true;
				}
			}
		}

		responsiveInit();
		$(window).resize(responsiveInit);
	});
})(jQuery);