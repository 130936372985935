(function($) {
	$(document).ready(function() {
		var tabletWidth = 768;
		var desktopWidth = 1130;
		var viewport;
		var state;
		var hasAppended  = false;
		var lastViewState = '';
		var initalHeight = parseInt($('body').find('.page-header').css('min-height'));

		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 *
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		function getViewportDimensions() {
			var w=window,
					d=document,
					e=d.documentElement,
					g=d.getElementsByTagName('body')[0],
					x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
			return { width:x,height:y };
		}

		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 *
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		function getVeiwPort() {
			viewport = getViewportDimensions();
			if(viewport.width >= desktopWidth) {
				return 'desktop';
			} else if ((viewport.width >= tabletWidth) && (viewport.width < desktopWidth)) {
				return 'tablet'
			} else {
				return 'mobile';
			}
		}

		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 *
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		function responsivePageHeader() {
			state = getVeiwPort();

			$('body').find('.page-header').each(function() {
				var height =  parseInt($(this).css('min-height'));

				if(!hasAppended) {
					var imgSet = $(this).find('.background-layer-holder .background-layer').attr('data-mk-img-set');
					var imgSet = $.parseJSON(imgSet);
					var url = imgSet.landscape.desktop;
					$(this).prepend('<img class="js-responsive-header-image" src="'+url+'" />');
					hasAppended = true;
				}

				if(state === 'mobile') {
					var minHeight = 0;
					$(this).css('min-height', minHeight+'px');
				}

				if(state === 'tablet') {
					var minHeight =  parseInt($(this).find('.js-responsive-header-image').outerHeight());
					$(this).css('min-height', minHeight+'px');
				}

				if(state === 'desktop') {
					$(this).css('min-height', initalHeight+'px');
				}


				// if(!hasAppended) {
				// 	if(state == 'mobile') {
				// 		var imgSet = $(this).find('.background-layer-holder .background-layer').attr('data-mk-img-set');
				// 		var imgSet = $.parseJSON(imgSet);
				// 		var url = imgSet.landscape.desktop;

				// 		$(this).prepend('<img class="js-responsive-header-image" src="'+url+'" />');
				// 		hasAppended = true;
				// 		return;
				// 	}

				// 	if(state == 'tablet') {
				// 		console.log(height);
				// 		if(hasAppended == true) {

				// 		}
				// 		$(this).css("min-height", (height / 2) + 'px');
				// 	}
				// }

				// return;
			});
		}

		/**
		 * -----------------------------------------------------------------------------------------------------------------
		 *
		 * -----------------------------------------------------------------------------------------------------------------
		 */
		responsivePageHeader();
		$(window).resize(responsivePageHeader);
	});
})(jQuery);