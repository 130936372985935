(function($) {
	$(document).ready(function() {
		$('body').find('.tjs-shortcode.cta_standard').each(function() {
			var currurl = window.location.pathname;
			$(this).find('.cta-content a')
				.attr('data-gacategory', 'call-to-action')
				.attr('data-gaaction', 'clicked')
				.attr('data-galabel', currurl)
				.attr('data-gahittype', 'event');

			$('body').on('click', '[data-gaaction]', function() {
				var category = $(this).attr('data-gacategory');
				var action = $(this).attr('data-gaaction');
				var label = $(this).attr('data-galabel');
				var hittype = $(this).attr('data-gahittype');

					ga('send', {
					  hitType: hittype,
					  eventCategory: category,
					  eventAction: action,
					  eventLabel: label,
					  value: 1
					});
			});
		});
	});
})(jQuery);