(function($) {
	$(document).ready(function() {
		var $mainMenu = $('.mk-main-navigation');

		$mainMenu.on('mouseover','.menu-item-link', function(event) {
			event.preventDefault();
			console.log('this has been clicked');
			// var position = $(this).position();
			// var width = $(this).outerWidth();
			// var iconplacement  = width / 50%

			var $this = $(this);
			var offset = $this.offset();
			var width = $this.width();
			var height = $this.height();

			var centerX = offset.left + width / 2;
			var centerY = offset.top + height / 2;

			$(this).next('.sub-menu').find('.menu-slider').css('left', centerX+"px");

			setTimeout(function(){
			 $this.closest('li').find('.gm-style').trigger('resize');
			}, 200);
		});
	});
})(jQuery);